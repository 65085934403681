import { BarRestaurant } from '@/components/Homepage/BarRestaurant';
import { Intro } from '@/components/Homepage/Intro';
import { RoomsCarousel } from '@/components/Homepage/RoomsCarousel';
import { Layout } from '@/components/Layout';
import { VideoCover } from '@/components/VideoCover';
import { GetHomepageDataQuery, getSdk } from '@/services/graphql';
import { graphqlClient } from '@/services/graphql-client';
import styles from '@/styles/Home.module.css';
import { FC } from 'react';

const Home: FC<GetHomepageDataQuery> = ({ HomePage }) => (
  <Layout pageTitle="Home">
    <main className={styles.main}>
      <VideoCover data={HomePage?.videoCover} />
      <Intro data={HomePage?.intro} />
      <RoomsCarousel data={HomePage?.roomsCarousel} />
      <BarRestaurant data={HomePage?.barRestaurant} />
    </main>
  </Layout>
);

export const getStaticProps = async () => {
  const sdk = getSdk(graphqlClient);
  const { data } = await sdk.getHomepageData();

  return {
    props: data,
  };
};
export default Home;
